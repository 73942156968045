
import { defineComponent, ref, reactive } from 'vue'
import Button from '@/components/UI/Button.vue'
import addFacility from '@/components/addFacility.vue'
import InputElement from '@/components/UI/Input.vue'
import Dropdown from '@/components/UI/Dropdown.vue'
import Tooltip from '@/components/UI/Tooltip.vue'
import ConvertToCommas from '@/filters/ConvertToCommas'
import { taxSettingsType } from '@/types'
export default defineComponent({
  name: 'External data',
  props: {
    bpmTaxSettings: {
      type: Array as () => taxSettingsType[],
      default: () => []
    },
    plugInInfo: {
      type: Array as () => taxSettingsType[],
      default: () => []
    },
    electricInfo: {
      type: Array,
      default: () => []
    },
    dieselSurtaxSettings: {
      type: Array,
      default: () => []
    },
    fiscalTaxSettings: {
      type: Array,
      default: () => []
    },
    incomeTaxSettings: {
      type: Array,
      default: () => []
    },
    settingsErrors: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const activeOption = ref(false)
    const operatorItems = reactive(['<', '=', '>'])
    const addFacility = (item) => {
      emit('addFacility', item)
    }
    const deleteFacility = (item) => {
      emit('deleteFacility', item)
    }
    const onColumnInput = (option) => {
      emit('onColumnInput', option)
    }
    const onUpdateDieselSurtax = (value, key) => {
      emit('onUpdateDieselSurtax', { value: value, key: key })
    }
    return {
      addFacility,
      onColumnInput,
      deleteFacility,
      onUpdateDieselSurtax,
      operatorItems,
      activeOption,
      ConvertToCommas
    }
  },
  components: {
    Button,
    addFacility,
    InputElement,
    Dropdown,
    Tooltip
  }
})
