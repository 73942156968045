
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    isMoreInfo: {
      type: Boolean,
      default: false
    },
    isBodyType: {
      type: Boolean,
      default: false
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    type: {
      type: String,
      default: ''
    }
  }
})
