
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Tabs',
  emits: ['selectedTab'],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    tabsOf: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const selectTab = (selectedTab) => {
       emit('selectedTab', selectedTab)
    }
    return { selectTab }
  }
})
