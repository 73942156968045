
import { defineComponent } from 'vue'
import InputElement from '@/components/UI/Input.vue'
import Button from '@/components/UI/Button.vue'
import { taxSettingsType } from '@/types'
import ConvertToCommas from '@/filters/ConvertToCommas'
export default defineComponent({
  props: {
    facility: {
      type: Array as () => taxSettingsType[],
      default: () => []
    },
    type: {
      type: String,
      default: ''
    },
    typeOf: {
      type: String,
      default: ''
    }
  },
  components: {
    InputElement,
    Button
  },
  setup(props) {
    const getFacilityProp = () => {
      let facilityType
      if (props.type == 'chargingFacility') {
        facilityType = 'chargingFacility'
      } else {
        facilityType = 'accessory'
      }
      return facilityType
    }
    return { getFacilityProp, ConvertToCommas }
  }
})
