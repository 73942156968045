
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Tabs',
  props: {
    selected: {
        type: Boolean,
        default: false
    } 
  }
})
